import React, { Component } from 'react';
import { connect } from 'react-redux';
import './logIn.css';
import { Button, Col, Input, Row } from 'antd';
import OtpInput from 'react-otp-input';
import * as qs from 'qs';
import { remove, get } from 'lodash';

import LogoImage from '../../../Image/logo.png';
import { history, toaster } from '../../../helpers';
import LinkImage from '../../../Image/link-icon.png';
import { acceptOTP, login, tokenLogin, getTenantInfo } from '../../../state-management/modules/authentication/action/user.actions'
import { setItem } from '../../../helpers';


let subDomain = '';
export class LogIn extends Component {

  constructor(props) {
    super(props);
    this.state = {
      otpScreen: false,
      email: '',
      error: {},
      formSubmit: false,
      otp: '',
      attempts: 3,
    }
  }

  componentWillMount() {
    const { location, dispatch } = this.props;
    const searchParam = qs.parse(location.search, { ignoreQueryPrefix: true });
    localStorage.clear()

    let token = searchParam && searchParam.token;
    if (token) {
      dispatch(tokenLogin(token))
        .then((res) => {
          setTimeout(() => {
            const returnUrl = searchParam.returnUrl;
            delete searchParam.token;
            if (returnUrl) {
              window.location.href = `${returnUrl}?${qs.stringify(searchParam)}`;
            }
            else {
              window.location.href = 'profile';
            }

          }, 50);
        })
    }

    var full = window.location.hostname;
    var parts;
    parts = full.split('.')
    remove(parts, (res) => { return ["", 'www', 'admin', 'api', 'dev', 'prod', 'ui'].indexOf(res) > -1 });
    if (parts.length > 0) {
      subDomain = parts[0];
    }

    dispatch(getTenantInfo(subDomain))
      .then((res) => {
        if (res.data && res.data.Tenants && res.data.Tenants.length > 0) {
          setItem('ssoLoginConfig', get(res.data, 'Tenants[0].SSOLoginConfig.Okta'));
        }
        this.setState({ tenantInfo: true })
      })
  }

  openOtpScreen = () => {
    const { email, error } = this.state
    this.setState({ error: {}, formSubmit: true });
    const { dispatch } = this.props;
    const params = {
      Email: email,
      Tenant: subDomain
    }
    if (email) {
      dispatch(login(params))
        .then((res) => {
          if (res.error) {
            toaster.error(res.message);
            return
          }

          this.setState({ otpScreen: true, formSubmit: false })
        })
    } else {
      error["email"] = true
      this.setState({ error });
    }

  }

  handleChange = (e) => {
    this.setState({ error: {}, email: e.target.value })
  }

  handleOtpChange = (otp) => {
    this.setState({ otp: otp, error: {}, formSubmit: false })
  }

  handleSubmit = () => {
    const { dispatch, user } = this.props
    const { otp, error, email, attempts } = this.state
    this.setState({ formSubmit: true, error: {} })
    const Session = (user.data && user.data.Session) || user.Session;
    // const Email = user.data && user.data.Email || user.ChallengeParameters.Email;
    const param = {
      Code: otp,
      Session: Session,
      Tenant: subDomain,
      Email: email
    }
    const userDetail = {
      Email: email,
      TenantName: subDomain
    }
    if (!otp || otp.length < 6) {
      error["required"] = true
    } else {
      dispatch(acceptOTP(param, userDetail))
        .then((res) => {
          if (res.error) {
            toaster.error(res.message);
            return
          }

          if (res.data && res.data.CognitoToken.AuthenticationResult && res.data.CognitoToken.AuthenticationResult.IdToken) {
            this.setState({ formSubmit: false })
            //history.push('/profile');
            setTimeout(() => {
              window.location.href = 'profile';
            }, 10);
          } else {
            if (attempts > 1) {
              const attempt = attempts - 1
              error["login"] = true;
              this.setState({ error, attempts: attempt })
            } else {
              toaster.error("Login Failed! You've made 3 unsuccessful attempts!")
              this.setState({
                error: {},
                attempts: 3,
                otpScreen: false,
                email: '',
                formSubmit: false,
                otp: '',
              })
            }
          }
        },
          error => {
            this.setState({ otpScreen: false })
            return error;
          });
    }

  }

  setSSOLogin = (isShow) => {
    history.push('/ssologin');
  }

  render() {
    const { otpScreen, email, otp, formSubmit, error, attempts, tenantInfo } = this.state;
    return (
      <div>
        <div>
          <div className='login-container'>
            <div className='oloid-box'>
              <img className="logo-image-login" src={LogoImage} alt="logo"></img>
              <div className='txt-login'>OLOID</div>
            </div>
            {
              tenantInfo &&
              <div className='login-box'>
                {
                  !otpScreen ? (
                    <div id="open-menu">
                      <div className='oloid-box-icon oloid-box-txt'>
                        <div className='txt-login'>Log in to your OloID Dashboard</div>
                        <div className='txt-login-subTxt'>A link will be sent to your email</div>
                      </div>
                      <label className="laval-text-input">Email Address*</label>
                      <Input placeholder='Email' name="email" className={`${formSubmit && error.email ? 'inputError' : ''}`} value={email} onChange={(e) => this.handleChange(e)} required />
                      <span className='helper-text float-left'>{formSubmit && error.email ? 'Required' : ''}</span>
                      <div className="bottom-next-btn">
                        <button onClick={this.openOtpScreen} className="next-btn">Next</button>
                      </div>

                      <div>
                        <div className="a-divider a-divider-break"><span className='or-txt'>OR</span></div>

                        <Row>
                          <Col span={24} className="text-center">
                            <Button className='sso-btn'
                              onClick={() => this.setSSOLogin(true)}
                            >
                              Log in with SSO
                            </Button>
                          </Col>
                        </Row>
                      </div>

                    </div>
                  ) :
                    (
                      <div>
                        <div className='oloid-box-icon'>
                          <img className="logo-image-link" src={LinkImage} alt="LinkImage"></img>
                        </div>
                        <div className='txt-login-box'>We have sent the login code!</div>
                        <div className='txt-login-subTxt'>Check your email inbox ({email}), enter the code here:</div>
                        <div>
                          <OtpInput
                            className="otp-box"
                            containerStyle={{ display: 'inline-flex' }}
                            //inputStyle={{ width: '40px', height: '50px', borderRadius: '5px', border: '1px solid #968484' }}
                            value={otp}
                            onChange={this.handleOtpChange}
                            numInputs={6}
                            separator={<span>-</span>}
                            isInputNum={true}
                          />
                        </div>
                        {
                          formSubmit && error.login &&
                          <div className="helper-text">Invalid Code, {attempts} attempts left!</div>
                        }
                        {
                          formSubmit && !otp &&
                          <div className="helper-text">OTP is required!</div>
                        }
                        {
                          formSubmit && otp && otp.length < 6 &&
                          <div className="helper-text">Not a valid entry!</div>
                        }
                        <button onClick={this.handleSubmit} disabled={error && error.redirect} className="next-btn">Login</button>
                      </div>
                    )
                }
              </div>
            }

          </div>

        </div>

      </div>
    )
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user,
  }
}
export default connect(mapStateToProps)(LogIn);
