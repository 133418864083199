import { occupancyConstants } from '../constants';
import { combineReducers } from 'redux'
import createReducer from '../../../../helpers/createReducer'

const locationsReducer = createReducer({})({
  [occupancyConstants.GET_ALL_LOCATION_SUCCESS]: (state, action) => action.payload
})

const occupancyReducer = createReducer({})({
  [occupancyConstants.GET_ALL_OCCUPANCIES_SUCCESS]: (state, action) => action.payload
})



export default combineReducers({
  allLocation: locationsReducer,
  allOccupancy: occupancyReducer,
})