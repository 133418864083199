import { profileConstants } from '../constants';
import { combineReducers } from 'redux'
import createReducer from '../../../../helpers/createReducer'


const connectionReducer = createReducer({})({
  [profileConstants.GET_USER_CONNECTION]: (state, action) => action.payload
})

const templateReducer = createReducer({})({
  [profileConstants.GET_CONNECTION_TEMPLATE]: (state, action) => action.payload
})

const transactionReducer = createReducer([])({
  [profileConstants.GET_USERS_TRANSACTION_SUCCESS]: (state, action) => action.payload
})

export default combineReducers({
  connection: connectionReducer,
  template: templateReducer,
  transaction: transactionReducer
})
