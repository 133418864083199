
import { useEffect } from 'react';
import { oktaLogin } from '../../../state-management/modules/authentication/action/user.actions'
import { useDispatch } from 'react-redux';
import { toaster } from '../../../helpers';
import { getOktaAuth } from './oktaAuth';
import { remove } from 'lodash';

let subDomain = '';
function LoginCallback() {
  const dispatch = useDispatch();

  useEffect(() => {
    var full = window.location.hostname;
    var parts;
    parts = full.split('.')
    remove(parts, (res) => { return ["", 'www', 'admin', 'api', 'dev', 'prod', 'ui'].indexOf(res) > -1 });
    if (parts.length > 0) {
      subDomain = parts[0];
    }

    setTokenMethod((authToken) => {
      handleLogin(authToken)
    })
  }, [])

  const setTokenMethod = (callback) => {
    const oktaAuth = getOktaAuth()
    oktaAuth.token
      .parseFromUrl()
      .then(async function (res) {
        // manage token or tokens
        var tokens = res.tokens

        // Setting token in token manager to get fetch access token from SDK
        oktaAuth.tokenManager.setTokens(tokens)
        let token = await oktaAuth.tokenManager.getTokensSync()
        //setOktaToken(token)

        const { idToken } = token
        const authToken = idToken.idToken

        //setAuthToken(authToken)
        callback(authToken)
      })
      .catch(function (err) {
        console.error('Error OKTA set token', err)
      })
  }

  const handleLogin = (token) => {
    dispatch(oktaLogin({
      "TenantName": subDomain,
      "Token": token,
      "Platform": 'Web'
    })).then((res) => {
      if (res.accesstoken) {
        setTimeout(() => {
          window.location.href = '/profile';
        }, 10);
      }
      if (res.message) {
        const msg = res.message.indexOf('Clientmatadata') > -1 ? 'Something went wrong please try again!' : res.message;
        toaster.error(msg);
      }
    });
  }

  return (
    <div>
    </div>
  );
}

export default LoginCallback;
