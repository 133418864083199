
import icons from '../../layout/icons';


const options = [
  {
    menuid: 1,
    key: "profile",
    urlPath: "profile",
    label: "Info",
    leftIcon: <div className='menu-icon home-icon'></div>
  },
  {
    menuid: 1,
    key: "vaccination",
    urlPath: "vaccination",
    label: "Vaccination",
    leftIcon: <div className='menu-icon vaccination-icon' ></div>
  },
  {
    menuid: 1,
    key: "occupancy",
    urlPath: "occupancy",
    label: "Occupancy",
    leftIcon: <div className='menu-icon occupancy-icon' ></div>
  },
  {
    menuid: 1,
    key: "credentials",
    urlPath: "credentials",
    label: "Credentials",
    leftIcon: <div className='menu-icon credentials-icon' ></div>
  }
];

export default options;
