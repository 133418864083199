import { notification } from 'antd';

export const error = (message) => {
  notification.error({
    message: 'Error',
    className: "toster-error",
    style: { width: 400 },
    description: message,
  })
};

export const success = (message) => {
  notification.success({
    message: 'Success',
    className: "toster-success",
    style: { width: 400 },
    description: message
  })
};

export const tostor = {
  success,
  error
}