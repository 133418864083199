// import Auth from '@aws-amplify/auth';
import { requestGet, requestPost, requestPut } from '../../../../services/requests';
import axios from 'axios';
/**
 * Supports all operations exposed by the user controller.
 */

export class UserService {

  constructor(endpoint) {
    this.endpoint = endpoint;
  }


  getTenantInfo(tenant = 'lift') {
    const url = this.operationUrl(`/user-tenant/info?tenant=${tenant}`);
    return requestGet(url, {}, true, {
      Authorization: process.env.REACT_APP_USER_API_KEY
    })
  }

  login(params) {
    const url = this.operationUrl('/user-login/authenticate');
    const param = params || {};
    return requestPost(url, param);
  }

  acceptOTP(params) {
    const url = this.operationUrl('/user-login/verify');
    const param = params || {};
    return requestPost(url, param);
  }

  getUserDetails() {
    const url = this.operationUrl(`/tenant-user/get`);
    return requestGet(url, { faceUrl: 1 })
  }

  updateUser(params) {
    const url = this.operationUrl('/tenant-user/update');
    const param = params || {};
    return requestPut(url, param);
  }

  googleLogin(params) {
    const url = this.operationUrl(`/federated-logins/google`);
    return new Promise((resolve, reject) => {
      return axios({
        url,
        method: 'POST',
        data: params,
        isLoading: true
      })
        .then(
          (response) => resolve(response.data && response.data.data),
          (error) => reject((error.response && error.response.data) || error))
    })
  };

  oktaLogin(params) {
    const url = this.operationUrl(`/federated-logins/okta`);
    return new Promise((resolve, reject) => {
      return axios({
        url,
        method: 'POST',
        data: params,
        isLoading: true
      })
        .then(
          (response) => resolve(response.data && response.data.data),
          (error) => reject((error.response && error.response.data) || error))
    })
  };

  azureLogin(params) {
    const url = this.operationUrl(`/federated-logins/azure`);
    return new Promise((resolve, reject) => {
      return axios({
        url,
        method: 'POST',
        data: params,
        isLoading: true
      })
        .then(
          (response) => resolve(response.data && response.data.data),
          (error) => reject((error.response && error.response.data) || error))
    })
  };


  // getUsersById(id, token) {
  //   const url = this.authUrl(`/users/${id}`);
  //   return new Promise((resolve, reject) => {
  //     return axios({
  //       url,
  //       method: 'GET',
  //       headers: {
  //         Authorization: token
  //       }
  //     })
  //       .then(
  //         (response) => resolve(response.data && response.data.data),
  //         (error) => reject((error.response && error.response.data) || error))
  //   })
  // };


  // setNewPassword(user, newPassword) {
  //   return new Promise((resolve, reject) => {
  //     Auth.completeNewPassword(user, newPassword)
  //       .then(success => resolve(success))
  //       .catch(err => reject(err));
  //   });
  // }

  // forgotPasswordSubmit(username, code, new_password) {
  //   return new Promise((resolve, reject) => {
  //     Auth.forgotPasswordSubmit(username, code, new_password)
  //       .then(success => resolve({message: "success"}))
  //       .catch(err => reject({message: "Error"}));
  //   });
  // }

  // forgotPassword(username) {
  //   return new Promise((resolve, reject) => {
  //     Auth.forgotPassword(username)
  //       .then(success => resolve(success))
  //       .catch(err => reject(err));
  //   });
  // }

  fbTokenLogin(parmas) {
    const url = this.operationUrl(`/user-login/app/login`);
    return requestPost(url, parmas)
  }

  logout() {
    localStorage.clear();
    localStorage.removeItem('user');
  }

  operationUrl(append) {
    return this.endpoint + append;
  }
}
