import React, { useEffect } from 'react';
import { history, tostor } from '../../helpers'
import { useDispatch } from 'react-redux';
import { fbTokenLogin } from '../../state-management/modules/authentication/action/user.actions'
import * as qs from 'qs';

export default function Occupancy(props) {
  const dispatch = useDispatch();

  useEffect(() => {
    const { location, match } = props;
    const searchParam = qs.parse(location.search, { ignoreQueryPrefix: true });
    localStorage.clear()
    let token = searchParam && searchParam.token;
    if (token) {
      dispatch(fbTokenLogin(token, {
        OloID: match.params.OloID,
        ReturnPath: '/login',
        QueryParams: {
          returnUrl: searchParam.returnPath ? `/${searchParam.returnPath}` : '/profile'
        }
      }))
        .then((res) => {
          if (res.error) {
            tostor.error(res.message);
            history.push('/login')
          }
          window.location.href = res.data.Location;
        })
    }
    else {
      history.push('/login')
    }
  }, [props, dispatch])

  return (
    <div className=''>
    </div>
  )

}