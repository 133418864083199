import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Menu } from 'antd';
import '../mainLayout.css';
import _ from 'lodash'
import options from './options';
import { Link } from 'react-router-dom';

const { SubMenu } = Menu;
const stripTrailingSlash = str => {
  if (str.substr(-1) === '/') {
    return str.substr(0, str.length - 1);
  }
  return str;
};

export class MainLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  getMenuItem = (menu) => {
    const { key, label, leftIcon, children, urlPath } = menu;
    const url = stripTrailingSlash(this.props.url);
    if (children) {
      return (
        <SubMenu
          key={key}
          title={label}
          icon={leftIcon}
        >
          {children.map(child => {
            const linkTo = child.withParent
              ? `${url}/${urlPath}/${child.urlPath}`
              : `${url}/${child.urlPath}`;
            return (
              <Menu.Item key={child.key}>
                <Link to={linkTo}>
                  {child.label}
                </Link>
              </Menu.Item>
            );
          })}
        </SubMenu>
      );
    }
    return (
      <Menu.Item key={key} icon={leftIcon}>
        <Link to={`${url}/${key}`}>
          {label}
        </Link>
      </Menu.Item>
    );
  };

  render() {
    const { app, user } = this.props;

    // let emailDomain = user.email && user.email.split('@');
    const menuOptions = [...options];
    let menus = menuOptions.filter(function (res) { return res.menuid === app.menuId });
    // menus = menus.filter(function (res) {
    //   if (res.access) {
    //     return res.access.indexOf((emailDomain && emailDomain[1])) > -1;
    //   }
    //   else {
    //     return true;
    //   }
    // });
    
    return (
      <div>
        <div className="logo" />
        <div className="left-menu-heading-text">Profile</div>
        <Menu theme="dark" mode="inline"
          // selectedKeys={[selectedItem]}
          // defaultOpenKeys={[currentItem]}
          defaultSelectedKeys={['1']}>
          {menus.map(menu =>
            this.getMenuItem(menu)
          )}
        </Menu>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  const { app } = state.App
  return {
    user,
    app
  }
}

export default connect(mapStateToProps)(MainLayout);
