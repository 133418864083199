import BackIcon from '../Image/back-arrow.png';
import BackIconBlack from '../Image/back-arrow-black.png';
import CameraBackIcon from '../Image/camera-roted-icon.png';
import FlashIcon from '../Image/flash-icon.png';
import CaptureImage from '../Image/capture-image.png';
import SelfieImage from '../Image/profile-image.png';
import SelfieSearchIcon from '../Image/selfie-search-icon.png';
import BackgroundImage from '../Image/camera-background-image.png';
import PlusIcon from '../Image/plus-icon.png';
import DeleteIcon from '../Image/delete-icon.png';
import RightIcon from '../Image/right-arrow-black.png';
import FaceIcon from '../Image/face-icon.png';
import WellnessIcon from '../Image/wellness-icon.png';
import BluetoothIcon from '../Image/bluetooth.png';
import FaceImageIcon from '../Image/face-image.png';
import NoImageIcon from '../Image/no-face.png';
import CloudKeyIcon from '../Image/cloud-key.png';
import LockIconActive from '../Image/cloud-key-active.png';
import HomeIcon from '../Image/Home.svg';
import credentialsIcon from '../Image/credentials.svg';
import occupancyIcon from '../Image/occupancy.svg';
import vaccinationIcon from '../Image/vaccination.svg';
import WhiteBackIcon from '../Image/white-close-icon.png';

export const icons = {
  BackIcon,
  BackIconBlack,
  CameraBackIcon,
  FlashIcon,
  CaptureImage,
  SelfieImage,
  SelfieSearchIcon,
  BackgroundImage,
  PlusIcon,
  DeleteIcon,
  RightIcon,
  FaceIcon,
  WellnessIcon,
  BluetoothIcon,
  FaceImageIcon,
  NoImageIcon,
  CloudKeyIcon,
  LockIconActive,
  HomeIcon,
  credentialsIcon,
  occupancyIcon,
  vaccinationIcon,
  WhiteBackIcon
}

export default icons;