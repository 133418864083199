import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../mainLayout.css';
import LogoImage from '../../Image/logo.png';
import {setSelectedMenu} from '../action/fixbar.action'
import Icon1 from '../../Image/ic_3_selected.png';
import _ from 'lodash'

export class FixSideBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedIndex: 0,
      Lists: [
        { id: 1, icon: Icon1, name: 'Profile' },
      ],
      collapsed: false,
      openMenu: false
    }
  }

  render() {
    const { user, menuId } = this.props;
    const { Lists } = this.state;

    return (
      <div className="leftBar">
        <div className="top-logo-box">
          <div className="logo-container">
            <img className="logo-image" alt="logo" src={LogoImage} />
          </div>
        </div>
        <ul className="list-bullet">
          {Lists.map((value, index) => (
            <li key={`item-${index}`} onClick={() => setSelectedMenu(value.id)} className="selected-menu">
              <div className="icon">
                <img className="laft-fixbar-icons" src={value.icon}></img>
              </div>
            </li>
          ))}
        </ul>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user
  }
}

export default connect(mapStateToProps)(FixSideBar);
