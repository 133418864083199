import React from 'react';
import ReactDOM from 'react-dom';
import { axiosInterceptors } from './helpers/axios-interceptors';
import App from './App';
import 'antd/dist/antd.css';
import './index.css';
import configureStore from './store';
import { Provider } from 'react-redux'
import { StoreContext } from 'redux-react-hook';

const store = configureStore();
axiosInterceptors();

ReactDOM.render(
  <Provider store={store}>
    {/* When using Redux React Hooks we must provide a StoreContext. Otherwise the hooks won't work. */}
    <StoreContext.Provider value={store}>
      <React.StrictMode>
        <App />
      </React.StrictMode>,
    </StoreContext.Provider>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

