import { combineReducers } from 'redux'
import createReducer from '../../helpers/createReducer'

const fixBarReducer = createReducer({ menuId: 1 })({
  'SET_SELECTED_MENU': (state, action) => {
    return {
      ...state,
      menuId: action.id
    };
  },
})

export default combineReducers({
  app: fixBarReducer
})
