// import logo from './logo.svg';
import LogIn from './modules/authentication/login/logIn';
import ssoLogins from './modules/authentication/login/ssologin';
import userFBLogin from './modules/middleware/userFBLogin';
import MainLayout from './layout/mainLayout'
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { history } from './helpers/history';
import '../src/layout/mainLayout.css';
import loader from './Image/loader.gif';
import LoginCallback from './modules/authentication/login/oktaLoginCallback'
// import veriff from './modules/veriff/veriff';


function App() {
  return (
    <div>
      <div className="ui_block ui-block-loader" id="ui_block">
        <div style={{
          margin: "auto",
          maxHeight: "100%"
        }}>
          <div>
            <img className="ui_block_image" src={loader} alt="" />
          </div>
        </div>
      </div>

      <div className="ui_block ui-block-loader" id="ui_block_manual">
        <div style={{
          margin: "auto",
          maxHeight: "100%"
        }}>
          <div>
            <img className="ui_block_image" src={loader} alt="" />
          </div>
        </div>
      </div>

      {/* <SiderDemo /> */}
      <Router history={history}>
        <Switch>
          <Redirect exact={true} from='/' to='/ssologin' />
          <Route exact path='/login' name='Login Page' component={LogIn} />
          <Route exact path='/ssologin' name='SSO Login Page' component={ssoLogins} />
          <Route path="/login/callback" component={LoginCallback} />
          <Route exact path='/app-user/:OloID' name='Login Page' component={userFBLogin} />
          {/* <Route exact path='/veriff' name='Login Page' component={veriff} /> */}
          <Route path='/' name='Home' component={MainLayout} />

        </Switch>
      </Router>
    </div>

  );
}

export default App;
