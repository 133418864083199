import React from 'react';
import Loadable from 'react-loadable';
import MainLayout from './layout/mainLayout';


function Loading() {
  return <div>Loading...</div>;
}

const Vaccination = Loadable({
  loader: () => import('./modules/vaccination/vaccination'),
  loading: Loading,
});
const Profile = Loadable({
  loader: () => import('./modules/Profile/profile'),
  loading: Loading,
});
const ParsonalInfo = Loadable({
  loader: () => import('./modules/Profile/parsonalInfo'),
  loading: Loading,
});
const Consent = Loadable({
  loader: () => import('./modules/Profile/consent'),
  loading: Loading,
});
const Transactions = Loadable({
  loader: () => import('./modules/Profile/transactions'),
  loading: Loading,
});
const Occupancy = Loadable({
  loader: () => import('./modules/occupancy/occupancy'),
  loading: Loading,
});
const Credentials = Loadable({
  loader: () => import('./modules/credentials/credentials'),
  loading: Loading,
});
const AddCredentials = Loadable({
  loader: () => import('./modules/credentials/addCredentials'),
  loading: Loading,
});
const OccupancyStatus = Loadable({
  loader: () => import('./modules/occupancy/occupancyStatus'),
  loading: Loading,
});

const Capture = Loadable({
  loader: () => import('./components/webCam/webCam'),
  loading: Loading,
});

const CloudKey = Loadable({
  loader: () => import('./modules/credentials/cloudKey/cloudKey'),
  loading: Loading,
});

const Veriff = Loadable({
  loader: () => import('./modules/veriff/veriff'),
  loading: Loading,
});
const Verify = Loadable({
  loader: () => import('./modules/Profile/verify'),
  loading: Loading,
});
const FrontIdScan = Loadable({
  loader: () => import('./modules/Profile/frontIdScan'),
  loading: Loading,
});
const BackIdScan = Loadable({
  loader: () => import('./modules/Profile/backIdScan'),
  loading: Loading,
});
const Selfie = Loadable({
  loader: () => import('./modules/Profile/selfie'),
  loading: Loading,
});
const VerifyContinue = Loadable({
  loader: () => import('./modules/Profile/verifyContinue'),
  loading: Loading,
});
const AddConsent = Loadable({
  loader: () => import('./modules/Profile/consentPreview'),
  loading: Loading,
});

const ViewPdf = Loadable({
  loader: () => import('./components/pdfView/pdfView'),
  loading: Loading,
});
const VaccinationCapture = Loadable({
  loader: () => import('./components/webCam/vaccinationWebCam'),
  loading: Loading,
});
const TestResult = Loadable({
  loader: () => import('./modules/testResult/testResult'),
  loading: Loading,
});
const VaccinationUpload = Loadable({
  loader: () => import('./modules/vaccination/vaccinationUpload'),
  loading: Loading,
});

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  { path: '/', exact: true, name: 'Home', component: MainLayout, allowedRoles: ['TenantUser', 'admin'] },
  { path: '/vaccination', exact: true, name: 'Vaccination', component: Vaccination, allowedRoles: ['TenantUser', 'admin'] },
  { path: '/profile', exact: true, name: 'Profile', component: Profile, allowedRoles: ['TenantUser', 'admin'] },
  { path: '/personal-info', exact: true, name: 'Personal Info', component: ParsonalInfo, allowedRoles: ['TenantUser', 'admin'] },
  { path: '/consent', exact: true, name: 'Consent', component: Consent, allowedRoles: ['TenantUser', 'admin'] },
  { path: '/transactions', exact: true, name: 'Transactions', component: Transactions, allowedRoles: ['TenantUser', 'admin'] },
  { path: '/occupancy', exact: true, name: 'Occupancy', component: Occupancy, allowedRoles: ['TenantUser', 'admin'] },
  { path: '/credentials', exact: true, name: 'Credentials', component: Credentials, allowedRoles: ['TenantUser', 'admin'] },
  { path: '/add-credentials', exact: true, name: 'Add Credentials', component: AddCredentials, allowedRoles: ['TenantUser', 'admin'] },
  { path: '/occupancy-status', exact: true, name: 'OccupancyStatus', component: OccupancyStatus, allowedRoles: ['TenantUser', 'admin'] },
  { path: '/capture/:type', exact: true, name: 'Capture', component: Capture, allowedRoles: ['TenantUser', 'admin'] },
  { path: '/cloudKey', exact: true, name: 'Cloud Key', component: CloudKey, allowedRoles: ['TenantUser', 'admin'] },
  { path: '/veriff', exact: true, name: 'Veriff', component: Veriff, allowedRoles: ['TenantUser', 'admin'] },
  { path: '/verify', exact: true, name: 'Verify', component: Verify, allowedRoles: ['TenantUser', 'admin'] },
  { path: '/frontIdScan', exact: true, name: 'FrontIdScan', component: FrontIdScan, allowedRoles: ['TenantUser', 'admin'] },
  { path: '/backIdScan', exact: true, name: 'BackIdScan', component: BackIdScan, allowedRoles: ['TenantUser', 'admin'] },
  { path: '/selfie', exact: true, name: 'Selfie', component: Selfie, allowedRoles: ['TenantUser', 'admin'] },
  { path: '/verifyContinue', exact: true, name: 'VerifyContinue', component: VerifyContinue, allowedRoles: ['TenantUser', 'admin'] },
  { path: '/add-consent/:id', exact: true, name: 'Add Consent', component: AddConsent, allowedRoles: ['TenantUser', 'admin'] },
  { path: '/view-pdf', exact: true, name: 'View Pdf', component: ViewPdf, allowedRoles: ['TenantUser', 'admin'] },
  { path: '/vaccinationWebCam/:type', exact: true, name: 'VaccinationCapture', component: VaccinationCapture, allowedRoles: ['TenantUser', 'admin'] },
  { path: '/testResult/:type', exact: true, name: 'TestResult', component: TestResult, allowedRoles: ['TenantUser', 'admin'] },
  { path: '/vaccinationUpload', exact: true, name: 'VaccinationUpload', component: VaccinationUpload, allowedRoles: ['TenantUser', 'admin'] }
];

export default routes;


