import { userConstants } from '../constants';
import { userService } from '../service';
import _ from 'lodash';
import { setItem, getParsedItem } from '../../../../helpers/localstore';

export const fetchLoginRequest = request => ({
  type: userConstants.LOGIN_REQUEST,
  payload: {
    loggingIn: true,
    user: request
  }
})

export const fetchLoginSuccess = request => ({
  type: userConstants.LOGIN_SUCCESS,
  payload: { user: { ...request } }
})
export const fetchLoginFailure = error => ({
  type: userConstants.LOGIN_FAILURE,
  payload: { user: {} }
})

export const login = (params) => {
  return dispatch => {
    dispatch(fetchLoginRequest({ params }));
    return userService.login(params)
      .then(response => {
        dispatch(fetchLoginSuccess(response));
        return response;
      },
        error => {
          dispatch(fetchLoginFailure(error));
          error.error = true;
          return error
        })
  }
}

export const acceptOTP = (param, userDetail) => {
  return dispatch => {
    dispatch(fetchLoginRequest({ param }));
    return userService.acceptOTP(param)
      .then(response => {
        const user = userDetail
        if (response.data.IsAuthorized) {
          user["IdToken"] = response.data.CognitoToken.AuthenticationResult.IdToken;
          user["accesstoken"] = response.data.CognitoToken.AuthenticationResult.IdToken;
          user["Role"] = "TenantUser"
          setItem('user', user);
          return userService.getUserDetails()
            .then(res => {
              const userDetail = {
                ...user,
                userDetail: res.data.user,
              }
              setItem('user', userDetail);
              dispatch(fetchLoginSuccess(userDetail));
              return response
            },
              error => {
                dispatch(fetchLoginFailure(error));
                return error
              })

        } else {
          dispatch(fetchLoginSuccess(response.data.CognitoToken));
        }
        return response

      },
        error => {
          dispatch(fetchLoginFailure(error));
          error.error = true;
          return error
        })
  }
}


export const azureLogin = (params) => {
  return dispatch => {
    return userService.azureLogin(params)
      .then(response => {
        var user = {
          ..._.get(response, 'User'),
          username: _.get(response, 'User.OloID'),
          accesstoken: _.get(response, 'CognitoToken'),
          email: _.get(response, 'User.Email'),
          role: _.get(response, 'User.Role'),
          tenantName: _.get(response, 'User.TenantName'),
          tenantID: _.get(response, 'User.TenantID'),
        };
        setItem('user', user);
        dispatch(fetchLoginSuccess(user));
        return user;
      },
        error => {
          dispatch(fetchLoginFailure(error));
          error.error = true;
          return error
        })
  }
}

export const googleLogin = (params) => {
  return dispatch => {
    return userService.googleLogin(params)
      .then(response => {
        var user = {
          ..._.get(response, 'User'),
          username: _.get(response, 'User.OloID'),
          accesstoken: _.get(response, 'CognitoToken'),
          email: _.get(response, 'User.Email'),
          role: _.get(response, 'User.Role'),
          tenantName: _.get(response, 'User.TenantName'),
          tenantID: _.get(response, 'User.TenantID'),
        };
        setItem('user', user);
        dispatch(fetchLoginSuccess(user));
        return user;

      },
        error => {
          dispatch(fetchLoginFailure(error));
          error.error = true;
          return error
        })
  }
}

export const oktaLogin = (params) => {
  return dispatch => {
    return userService.oktaLogin(params)
      .then(response => {
        var user = {
          ..._.get(response, 'User'),
          username: _.get(response, 'User.OloID'),
          accesstoken: _.get(response, 'CognitoToken'),
          email: _.get(response, 'User.Email'),
          role: _.get(response, 'User.Role'),
          tenantName: _.get(response, 'User.TenantName'),
          tenantID: _.get(response, 'User.TenantID'),
        };
        setItem('user', user);
        dispatch(fetchLoginSuccess(user));
        return user;

      },
        error => {
          dispatch(fetchLoginFailure(error));
          error.error = true;
          return error
        })
  }
}

export const tokenLogin = (token) => {
  return dispatch => {

    if (token) {
      let user = {}
      user["IdToken"] = token;
      user["accesstoken"] = token;
      user["Role"] = "TenantUser"
      setItem('user', user);
      return userService.getUserDetails()
        .then(res => {
          const userDetail = {
            ...user,
            userDetail: res.data.user,
          }
          setItem('user', userDetail);
          dispatch(fetchLoginSuccess(userDetail));
          return res
        },
          error => {
            dispatch(fetchLoginFailure(error));
            return error
          })

    }
  }
}


export const getUserDetails = () => {
  return dispatch => {
    return userService.getUserDetails()
      .then(res => {
        const userDetail = getParsedItem('user');
        userDetail['userDetail'] = res.data.user;
        setItem('user', userDetail);
        dispatch(fetchLoginSuccess(userDetail));
        return res
      },
        error => {
          error.error = true;
          return error
        })
  }
}

export const updateUser = (params) => {
  return dispatch => {
    return userService.updateUser(params)
      .then(response => {
        return userService.getUserDetails()
          .then(res => {
            const userDetail = getParsedItem('user')
            userDetail['userDetail'] = res.data.user;
            setItem('user', userDetail);
            dispatch(fetchLoginSuccess(userDetail));
            return response
          },
            error => {
              dispatch(fetchLoginFailure(error));
              return error
            })
      },
        error => {
          dispatch(fetchLoginFailure(error));
          error.error = true;
          return error
        })
  }
}

export const userlogout = () => ({
  type: userConstants.LOGOUT,
  payload: { user: {} }
})


export const logout = () => {
  return dispatch => {
    userService.logout();
    return dispatch(userlogout());
  }
}

export const fbTokenLogin = (token, params) => {
  return dispatch => {
    if (token) {
      let user = {}
      user["IdToken"] = token;
      user["accesstoken"] = token;
      user["Role"] = "TenantUser"
      setItem('user', user);
      return userService.fbTokenLogin(params)
        .then(res => {
          return res;
        },
          error => {
            error.error = true;
            return error
          })
    }
  }
}

export const getTenantInfo = (subDomain) => {
  return dispatch => {
    return userService.getTenantInfo(subDomain)
      .then(res => {
        return res
      },
        error => {
          error.error = true;
          return error
        })
  }
}