import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  SettingFilled,
  ApiFilled,
  FlagFilled,
  PlusCircleFilled,
  MailFilled,
  LogoutOutlined
} from '@ant-design/icons';
import { Layout, Menu, Row, Col, Dropdown, message } from 'antd';
import './mainLayout.css';
import Voalt from '../Image/vault.png';
import User from '../Image/user.png';
import Endpoint from '../Image/endpoint.png';
import Icon1 from '../Image/ic_3_selected.png';
import MenuIcon from '../Image/menu.png';
import CloseIcon from '../Image/close.png';
import VaccinationCardIcon from '../Image/vacc-card.png';
import CameraIcon from '../Image/camera.png';
import Syringe from '../Image/syringe.png';
import routes from '../routes';
import _ from 'lodash'
import FixSideBar from './fixSideBar/fixSideBar'
import LeftNavigation from './leftNavigation/leftNavigation'
import { logout } from '../state-management/modules/authentication/action/user.actions'
import { history } from '../helpers/history'

const { Header, Sider, Content } = Layout;
export class MainLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedIndex: 0,
      Lists: [
        { id: 1, icon: Icon1 },
      ],
      collapsed: false,
      openMenu: false
    }
  }

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  openMenu = () => {
    this.setState({
      openMenu: true
    })
  }

  closeMenu = () => {
    this.setState({
      openMenu: false
    })
  }
  onClick = ({ key }) => {
    const { dispatch } = this.props;

    if (key === '4') {
      dispatch(logout())
      history.push('/vaccination');
    }
  };

  goToPage = (page) => {
    history.push(page);
    this.closeMenu()
  }

  render() {
    const { user } = this.props;
    const { url } = this.props.match;
    const filteredRoutes = _.filter(routes, (route) => {
      return user && (route.allowedRoles.indexOf(user.Role) > -1)
    })

    const { Lists, openMenu } = this.state;
    const { SubMenu } = Menu;

    const menu = (
      <Menu onClick={this.onClick}>
        <Menu.Item key="1">
          <FlagFilled className='menu-icons' />Us-East-2
        </Menu.Item>
        <Menu.Item key="2">
          <SettingFilled className='menu-icons' />Setting
        </Menu.Item>
        <Menu.Item key="3">
          <MailFilled className='menu-icons' />{user.userDetail && user.userDetail.Email}
        </Menu.Item>
        <Menu.Item key="4">
          <LogoutOutlined className='menu-icons' />Logout
        </Menu.Item>
      </Menu>
    );
    return (
      <div className='full-page'>
        <FixSideBar url={url} />
        <Layout>

          <Sider trigger={null} collapsible collapsed={this.state.collapsed}>
            <LeftNavigation url={url} />
          </Sider>


          <Layout className="site-layout">

            <Header className="site-layout-background" style={{ padding: 0 }}>
              <div className="headetr-right-container">
                <div className="notification-icon">
                  <span className='bell-icon'></span>
                </div>
                <Dropdown overlay={menu}>
                  <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                    <div className="profile-corcle"><span className="profile-corcleTxt">{user.userDetail && user.userDetail.DisplayName[0].toUpperCase()}</span></div>
                  </a>
                </Dropdown>
              </div>
              {React.createElement(this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                className: 'trigger',
                onClick: this.toggle,
              })}


              {!openMenu ?
                <img src={MenuIcon} alt="menu" className="menu-btn menu-button" id="open-menu" onClick={this.openMenu}></img> :
                <img src={CloseIcon} alt="menu" className="close-button" id="close-menu" onClick={this.closeMenu} />
              }

              {
              openMenu &&
                <nav id="overlay" className={openMenu ? "show-menu" : ""}>
                  <div className="menu-background">
                    <ul>
                      <li>
                        <div>
                        </div>
                      </li>
                      <li className="menu-list-border">
                        <img className="mobile-menu-icon" src={Voalt} ></img>
                        <a href="#">Vault</a>
                        <div>
                          <Row className="row-text-color">
                            <Col span={12}>
                              <div className="span-text" onClick={() => { this.goToPage('/vaccination') }}>
                                Vaccination
                            </div>
                            </Col>
                            <Col span={12}>
                              <div className="span-text" onClick={() => { this.goToPage('/profile') }}>
                                Profile
                            </div>
                            </Col>
                            <Col span={12}>
                              <div className="span-text" onClick={() => { this.goToPage('/occupancy') }}>
                                Occupancy
                            </div>
                            </Col>

                            <Col span={12}>
                              <div className="span-text" onClick={() => { this.goToPage('/credentials') }}>
                              Credentials
                            </div>
                            </Col>
                          </Row>
                        </div>
                      </li>

                    </ul>

                  </div>
                </nav>
              }
            </Header>


            <Switch>
              {
                filteredRoutes.map((route, idx) => {
                  return route.component ? (<Route key={idx} path={route.path} exact={route.exact}
                    name={route.name}
                    render={props => (
                      localStorage.getItem('user')
                        ? <route.component {...props} />
                        : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
                    )} />)
                    : (localStorage.getItem('user')
                      ? ''
                      : <Redirect key={idx} to={{ pathname: '/login' }} />);
                })
              }

              {
                (!user || !user.IdToken) &&
                <Redirect to={{ pathname: '/login' }} />
              }
              {
                user && user.IdToken &&
                <Redirect from="/" to="/profile" />
              }

            </Switch>
          </Layout>
        </Layout>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user
  }
}

export default connect(mapStateToProps)(MainLayout);
